<template>
  <el-form
    :label-position="'left'"
    label-width="80px"
    @submit.prevent="onSubmit"
    style="margin: 20px; width: 60%; min-width: 600px"
  >
    <el-form-item label="练习名称">
      <el-input v-model="requestContent.practiceName"></el-input>
    </el-form-item>

    <el-form-item label="题目描述">
      <aidaFeedEditor
        :openMd="true"
        :openHtml="true"
        :openCos="true"
        :openVod="false"
        :openPractice="false"
        v-model="requestContent.fillBlankAsset.description"
      ></aidaFeedEditor>
    </el-form-item>

    <el-form-item label="解析">
      <aidaFeedEditor
        :openMd="true"
        :openHtml="true"
        :openCos="true"
        :openVod="false"
        :openPractice="false"
        v-model="requestContent.fillBlankAsset.analysis"
      ></aidaFeedEditor>
    </el-form-item>
    <el-form-item label="答案">
      <aidaFeedEditor
        :openMd="true"
        :openHtml="true"
        :openCos="true"
        :openVod="false"
        :openPractice="false"
        v-model="requestContent.fillBlankAsset.answer"
      ></aidaFeedEditor>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onAddFillinBlankAsset">{{ requestBtnText }}</el-button>
      <el-button>取消</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { MarkdownFileType } from "@/util/EnumUtil";
import aidaFeedEditor from "@/component/aidaFeedEditor";
import { alertMessage } from "@/util/alertMessageUtil.js";
import { setTimeout } from "timers";
import { practiceApi } from "@/api/practiceApi";
export default {
  components: {
    aidaFeedEditor,
  },
  data() {
    return {
      requestStatus: true,
      requestContent: {
        fillBlankAsset: {
          analysis: [
            {
              unitType: MarkdownFileType.MARKDOWN,
              unitId: 0,
              index: 0,
              content: "",
              fileName: "",
              url: "",
              markDown: {
                content: "",
              },
            },
          ],
          description: [
            {
              unitType: MarkdownFileType.MARKDOWN,
              unitId: 0,
              index: 0,
              content: "",
              fileName: "",
              url: "",
              markDown: {
                content: "",
              },
            },
          ],
          answer: [
            {
              unitType: MarkdownFileType.MARKDOWN,
              unitId: 0,
              index: 0,
              content: "",
              fileName: "",
              url: "",
              markDown: {
                content: "",
              },
            },
          ],
        },
        practiceId: 0,
        practiceName: "",
      },
      requestBtnText: "立即创建",
    };
  },
  methods: {
    onAddFillinBlankAsset() {
      setTimeout(() => {
        this.requestStatus = true;
        let alertText = "请填写练习名";
        let alertType = "warning";
        if (!this.requestContent.practiceName) {
          alertMessage(alertText, alertType);
          return;
        }

        if (this.requestStatus) {
          practiceApi.fillBlank(this.requestContent).then((data) => {
            if (data.code === 0) {
              if (this.requestBtnText === "立即创建") {
                alertMessage("创建成功", "success");
              } else {
                alertMessage("修改成功", "success");
              }
            } else {
              alertMessage(data.msg, "warning");
            }
          });
        }
      }, 200);
    },
    onSubmit() {},
  },
  mounted() {
    if (this.$route.params.id) {
      this.requestBtnText = "立即修改";
      practiceApi.getFillBlankPracticeById(this.$route.params.id).then((ret) => {
        if (ret.code === 0) {
          let newData = {
            fillBlankAsset: ret.data.fillBlankAssetVO,
            practiceId: ret.data.practiceId,
            practiceName: ret.data.practiceName,
          };
          this.requestContent = newData;
        } else {
          alertMessage("获取数据失败，请稍后再试", "warning");
        }
      });
    }
  },
  filters: {
    capitalize: function(val) {
      //return String.fromCharCode(97 + val)
      return val.toString();
    },
  },
};
</script>

<style lang="less" scoped></style>
