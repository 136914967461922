import ajax from "./ajax.js";

let base = process.env.VUE_APP_BASE_URL;

const choiceAsset = (params) => {
  return ajax.post(`${base}/api/itedu/v1/practice/choice`, params);
};

const fillBlank = (params) => {
  return ajax.post(`${base}/api/itedu/v1/practice/fillblank`, params);
};

const listPractice = (practiceType, pageNo) => {
  return ajax.get(
    `${base}/api/itedu/v1/practice/query/${pageNo}?practiceType=${practiceType}&pageNo=${pageNo}`
  );
};

const searchPractice = (practiceType, practiceName) => {
  return ajax.get(
    `${base}/api/itedu/v1/practice/search?practiceType=${practiceType}&practiceName=${practiceName}`
  );
};

const deletePractice = (practiceId) => {
  return ajax.post(
    `${base}/api/itedu/v1/practice/delete?practiceId=${practiceId}`
  );
};
const getChoicePracticeById = (practiceId) => {
  return ajax.get(`${base}/api/itedu/v1/practice/choice/${practiceId}`);
};
const getFillBlankPracticeById = (practiceId) => {
  return ajax.get(`${base}/api/itedu/v1/practice/fillblank/${practiceId}`);
};

export const practiceApi = {
  choiceAsset: choiceAsset,
  fillBlank: fillBlank,
  listPractice: listPractice,
  searchPractice: searchPractice,
  deletePractice: deletePractice,
  getChoicePracticeById: getChoicePracticeById,
  getFillBlankPracticeById: getFillBlankPracticeById,
};
